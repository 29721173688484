
import PanThumb from '@/components/pan-thumb/Index.vue'
import Mallki from '@/components/text-hover-effect/Mallki.vue'
import { useStore } from '@/store'
import { computed, defineComponent } from 'vue'
export default defineComponent({
  components: {
    PanThumb,
    Mallki
  },
  setup() {
    const store = useStore()
    const name = computed(() => {
      return store.state.user.name
    })

    const avatar = computed(() => {
      return store.state.user.avatar
    })
    const roles = computed(() => {
      return store.state.user.roles
    })

    return {
      name,
      avatar,
      roles
    }
  }
})

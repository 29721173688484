
import { defineComponent, reactive, toRefs } from 'vue'
import { dependencies, devDependencies } from '../../../../../package.json'
export default defineComponent({
  setup() {
    const state = reactive({
      updateTime: process.env.VUE_APP_UPDATE_TIME
    })
    return {
      dependencies,
      devDependencies,
      ...toRefs(state)
    }
  }
})

<!--
 * @Description: admin 权限主页
 * @Author: ZY
 * @Date: 2021-01-15 18:44:25
 * @LastEditors: SCY
 * @LastEditTime: 2021-04-02 09:40:07
-->
<template>
  <div class="dashboard-editor-container">
    <!-- <GithubCorner class="github-corner" /> -->
    <PanelGroup @handle_set_line_chart_data="handleSetLineChartData" />
    <el-row style="background:#fff;padding:16px 16px 0;margin-bottom:32px;border-radius: 8px;">
      <LineChart :chart-data="xChartData" :xaxis="xAxisOptions" />
    </el-row>

    <el-row :gutter="32">
      <!-- <el-col :xs="24" :sm="24" :lg="8">
        <div class="chart-wrapper">
          <RadarChart />
        </div>
      </el-col> -->
      <el-col :xs="24" :sm="24" :lg="8">
        <div class="chart-wrapper">
          <PieChart :chart-data="pieChartData" :title="`收入渠道`" />
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :lg="8">
        <div class="chart-wrapper">
          <BarChart />
        </div>
      </el-col>
    </el-row>

    <el-row :gutter="8">
      <el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }" :lg="{ span: 16 }" :xl="{ span: 16 }"
        style="padding-right:8px;margin-bottom:30px;">
        <DependsTable style="border-radius: 8px;" />
      </el-col>
      <el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }" :lg="{ span: 8 }" :xl="{ span: 8 }"
        style="padding-right:8px;margin-bottom:30px;">
        <TodoList style="border-radius: 8px;" />
      </el-col>
    </el-row>

    <el-row>
      <el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }" :lg="{ span: 16 }" :xl="{ span: 16 }"
        style="padding-right:8px;margin-bottom:30px;">
        <UpdateTimeline style="border-radius: 16px;" />
      </el-col>
      <el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }" :lg="{ span: 8 }" :xl="{ span: 8 }"
        style="padding-right:8px;margin-bottom:30px;">
        <BoxCard style="border-radius: 8px;" />
      </el-col>
    </el-row>
  </div>
</template>

<script lang="ts">
import GithubCorner from '@/components/github-corner/Index.vue'
import { SeriesOption } from 'echarts'
import { reactive, toRefs } from 'vue'
import BarChart from './components/BarChart.vue'
import BoxCard from './components/BoxCard.vue'
import DependsTable from './components/DependsTable.vue'
import LineChart from './components/LineChart.vue'
import PanelGroup from './components/PanelGroup.vue'
import PieChart from './components/PieChart.vue'
import RadarChart from './components/RadarChart.vue'
import UpdateTimeline from './components/UpdateTimeline.vue'
import TodoList from './components/todo-list/Index.vue'
export default {
  components: {
    GithubCorner,
    PanelGroup,
    LineChart,
    RadarChart,
    PieChart,
    BarChart,
    DependsTable,
    TodoList,
    BoxCard,
    UpdateTimeline
  },
  setup() {
    const dataMap = reactive({
      xAxisOptions: [] as string[],
      xChartData: {} as { [k: string]: Partial<SeriesOption & { sortid: number }> },
      pieChartData: [], // 饼图数据
    })
    const handleSetLineChartData = (a: string[], data: any, piechatdata: any) => {
      dataMap.xAxisOptions = a;
      dataMap.xChartData = data;
      dataMap.pieChartData = piechatdata;
    }

    return {
      ...toRefs(dataMap),
      handleSetLineChartData
    }
  }
}
</script>

<style lang="scss" scoped>
.dashboard-editor-container {
  padding: 32px;
  background-color: #F2F7FF;
  position: relative;

  .github-corner {
    position: absolute;
    top: 0px;
    border: 0;
    right: 0;
  }

  .chart-wrapper {
    background: #fff;
    padding: 16px 16px 0;
    margin-bottom: 32px;
    border-radius: 8px;
  }
}

@media (max-width:1024px) {
  .chart-wrapper {
    padding: 8px;
  }
}
</style>

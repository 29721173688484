
import resize from '@/components/charts/mixins/resize'
import { EChartsOption, SeriesOption, init } from 'echarts'
import { PropType, defineComponent, nextTick, onActivated, onBeforeUnmount, onDeactivated, onMounted, watchEffect } from 'vue'

type LineChartData = { [k: string]: Partial<SeriesOption & { sortid: number }> }
export default defineComponent({
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%',
      required: true
    },
    height: {
      type: String,
      default: '350px',
      required: true
    },

    // 横坐标
    xaxis: {
      type: Array as PropType<string[]>,
      required: true,
    },
    // 数据
    chartData: {
      type: Object as PropType<LineChartData>,
      default: {},
    },
  },
  setup(props) {
    const {
      mounted,
      chart,
      beforeDestroy,
      activated,
      deactivated
    } = resize()
    const chartdatadefault = [{
      name: 'expected',
      itemStyle: {
        color: '#FF005A',
        lineStyle: {
          color: '#FF005A',
          width: 2
        }
      },
      smooth: false,
      type: 'line',
      data: [] as number[],
      animationDuration: 2800,
      animationEasing: 'cubicInOut'
    },
    {
      name: 'actual',
      smooth: true,
      type: 'line',
      itemStyle: {
        color: '#3888fa',
        lineStyle: {
          color: '#3888fa',
          width: 2
        },
        areaStyle: {
          color: '#f3f8ff'
        }
      },
      data: [] as number[],
      animationDuration: 2800,
      animationEasing: 'quadraticOut'
    }]

    const setOptions = (chartData: LineChartData) => {
      if (chart.value) {
        const xData = Object.keys(chartData);
        xData.sort((a, b) => { return (chartData[a].sortid || 0) - (chartData[b].sortid || 0) });
        let i = 0;
        const seriesData = xData.map(v => {
          const r = Object.assign(Object.assign({}, chartdatadefault[i]), chartData[v]);
          r.name = v;
          i++;
          return r;
        });
        chart.value.setOption({
          xAxis: {
            data: props.xaxis,
            boundaryGap: false,
            axisTick: {
              show: false
            }
          },
          grid: {
            left: 10,
            right: 10,
            bottom: 20,
            top: 30,
            containLabel: true
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross'
            },
            padding: 8
          },
          yAxis: {
            axisTick: {
              show: false
            }
          },
          legend: {
            data: xData
          },
          series: seriesData
        } as EChartsOption)
      }
    }

    watchEffect(() => {
      if (props.chartData) {
        setOptions(props.chartData)
      }
    })

    const initChart = () => {
      const lineChart = init(document.getElementById('homeLineCharts') as HTMLDivElement, 'macarons')
      setOptions(props.chartData)
      chart.value = lineChart
    }

    onMounted(() => {
      mounted()
      nextTick(() => {
        initChart()
      })
    })

    onBeforeUnmount(() => {
      beforeDestroy()
      if (!chart.value) {
        return
      }
      chart.value.dispose()
      chart.value = null
    })

    onActivated(() => {
      activated()
    })

    onDeactivated(() => {
      deactivated()
    })

    return {

    }
  }
})

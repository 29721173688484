<!--
 * @Description: 首页饼图组件
 * @Author: ZY
 * @Date: 2021-01-18 11:08:53
 * @LastEditors: ZY
 * @LastEditTime: 2021-01-26 10:19:45
-->
<template>
  <div id="homePieCharts" :class="className" :style="{ height: height, width: width }" />
</template>

<script lang="ts">
import resize from '@/components/charts/mixins/resize'
import { EChartsOption, init } from 'echarts'
import { PropType, defineComponent, nextTick, onActivated, onBeforeUnmount, onBeforeUpdate, onDeactivated, onMounted } from 'vue'
export default defineComponent({
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%',
      required: true
    },
    height: {
      type: String,
      default: '300px',
      required: true
    }
    ,
    title: {
      type: String,
      default: '',
    },
    chartData: {
      type: Array as PropType<{ name: string, value: number }[]>,
      default: [],
    }
  },
  setup(prop) {
    const {
      mounted,
      chart,
      beforeDestroy,
      activated,
      deactivated
    } = resize()

    const initChart = () => {
      const pieChart = init(document.getElementById('homePieCharts') as HTMLDivElement, 'macarons')

      pieChart.setOption({
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
          left: 'center',
          bottom: '10',
          data: prop.chartData.map(item => item.name),
        },
        series: [
          {
            name: prop.title,
            color: ['#435EBE', '#5DDAB4', '#9694FF', '#FF7976', '#57CAEB'],
            type: 'pie',
            roseType: 'radius',
            radius: [15, 95],
            center: ['50%', '38%'],
            data: prop.chartData,
            animationEasing: 'cubicInOut',
            animationDuration: 2600
          }
        ]
      } as EChartsOption)
      chart.value = pieChart
    }

    onMounted(() => {
      mounted()
      nextTick(() => {
        initChart()
      })
    })

    onBeforeUnmount(() => {
      // if (!chart.value) {
      //   return
      // }
      // chart.value.dispose()
      // chart.value = null
      beforeDestroy()
    })
    onBeforeUpdate(() => {
      initChart()
    })
    onActivated(() => {
      activated()
    })

    onDeactivated(() => {
      deactivated()
    })

    return {

    }
  }
})
</script>


import GithubCorner from '@/components/github-corner/Index.vue'
import { SeriesOption } from 'echarts'
import { reactive, toRefs } from 'vue'
import BarChart from './components/BarChart.vue'
import BoxCard from './components/BoxCard.vue'
import DependsTable from './components/DependsTable.vue'
import LineChart from './components/LineChart.vue'
import PanelGroup from './components/PanelGroup.vue'
import PieChart from './components/PieChart.vue'
import RadarChart from './components/RadarChart.vue'
import UpdateTimeline from './components/UpdateTimeline.vue'
import TodoList from './components/todo-list/Index.vue'
export default {
  components: {
    GithubCorner,
    PanelGroup,
    LineChart,
    RadarChart,
    PieChart,
    BarChart,
    DependsTable,
    TodoList,
    BoxCard,
    UpdateTimeline
  },
  setup() {
    const dataMap = reactive({
      xAxisOptions: [] as string[],
      xChartData: {} as { [k: string]: Partial<SeriesOption & { sortid: number }> },
      pieChartData: [], // 饼图数据
    })
    const handleSetLineChartData = (a: string[], data: any, piechatdata: any) => {
      dataMap.xAxisOptions = a;
      dataMap.xChartData = data;
      dataMap.pieChartData = piechatdata;
    }

    return {
      ...toRefs(dataMap),
      handleSetLineChartData
    }
  }
}

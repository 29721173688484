
import storage, { StorageType } from '@/utils/storage'
import { computed, defineComponent, reactive, ref } from 'vue'
import Todo, { TodoItem } from './Todo.vue'
const STORAGE_KEY = 'todos'

export default defineComponent({
  name: 'TodoList',
  components: {
    Todo
  },
  setup() {
    const filters: { [key: string]: Function } = {
      all: (todos: TodoItem[]) => todos,
      active: (todos: TodoItem[]) => todos.filter(todo => !todo.done),
      completed: (todos: TodoItem[]) => todos.filter(todo => todo.done)
    }
    const getFilters = computed(() => filters)

    const visibility = ref('all')
    let todos = reactive([
      { text: 'star this repository', done: true },
      { text: 'fork this repository', done: false },
      { text: 'settings', done: false },
      { text: 'theme', done: false },
      { text: 'doc', done: false },
      { text: 'gitpages', done: false }
    ])

    const pluralize = (n: number, s: string) => {
      return n === 1 ? s : s + 's'
    }

    const capitalize = (s: string) => {
      return s.charAt(0).toUpperCase() + s.slice(1)
    }

    const allChecked = computed(() => {
      return todos.every(todo => todo.done)
    })

    const filteredTodos = computed(() => {
      return getFilters.value[visibility.value](todos)
    })

    const remaining = computed(() => {
      return todos.filter(todo => !todo.done).length
    })

    const setLocalStorage = () => {
      storage.rcSetItem(StorageType.local, STORAGE_KEY, JSON.stringify(todos))
    }

    const addTodo = (e: KeyboardEvent) => {
      const text = (e.target as HTMLInputElement).value
      if (text.trim()) {
        todos.push({
          text,
          done: false
        })
        setLocalStorage()
      }
      (e as any).target.value = ''
    }

    const toggleTodo = (todo: TodoItem) => {
      todo.done = !todo.done
      setLocalStorage()
    }

    const deleteTodo = (todo: TodoItem) => {
      todos.splice(todos.indexOf(todo), 1)
      setLocalStorage()
    }

    const editTodo = ({ todo, value }: { todo: TodoItem, value: string }) => {
      todo.text = value
      setLocalStorage()
    }

    const clearCompleted = () => {
      todos = todos.filter(todo => !todo.done)
      setLocalStorage()
    }

    const toggleAll = ({ done }: { done: boolean }) => {
      todos.forEach(todo => {
        todo.done = done
        setLocalStorage()
      })
    }

    return {
      visibility,
      todos,
      getFilters,
      pluralize,
      capitalize,
      allChecked,
      filteredTodos,
      remaining,
      addTodo,
      toggleTodo,
      deleteTodo,
      editTodo,
      clearCompleted,
      toggleAll
    }
  }

})

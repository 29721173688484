
import GithubCorner from '@/components/github-corner/Index.vue'
import PanThumb from '@/components/pan-thumb/Index.vue'
import { useStore } from '@/store'
import { computed, defineComponent } from 'vue'
export default defineComponent({
  components: {
    PanThumb,
    GithubCorner
  },
  setup() {
    const emptyGif = 'https://wpimg.wallstcn.com/0e03b7da-db9e-4819-ba10-9016ddfdaed3'
    const store = useStore()
    const name = computed(() => {
      return store.state.user.name
    })
    const avatar = computed(() => {
      return store.state.user.avatar
    })
    const roles = computed(() => {
      return store.state.user.roles
    })

    return {
      emptyGif,
      name,
      avatar,
      roles
    }
  }
})

<!--
 * @Description:
 * @Author: ZY
 * @Date: 2021-01-18 10:48:43
 * @LastEditors: SCY
 * @LastEditTime: 2021-04-02 10:00:26
-->
<template>
  <el-row :gutter="40" class="panel-group">
    <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col">
      <div class="card-panel" @click="handleSetLineChartData('playernew')">
        <div class="card-panel-icon-wrapper icon-people">
          <img :src="visits">
        </div>
        <div class="card-panel-description">
          <div class="card-panel-text">
            今日新增
          </div>
          <CountTo :start-val="0" :end-val="todayPlayerNew" :duration="2600" class="card-panel-num" />
        </div>
      </div>
    </el-col>
    <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col">
      <div class="card-panel" @click="handleSetLineChartData('playeronline')">
        <div class="card-panel-icon-wrapper icon-message">
          <img :src="messages">
        </div>
        <div class="card-panel-description">
          <div class="card-panel-text">
            当前在线
          </div>
          <CountTo :start-val="0" :end-val="todayPlayerOnline" :duration="3000" class="card-panel-num" />
        </div>
      </div>
    </el-col>
    <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col">
      <div class="card-panel" @click="handleSetLineChartData('battlecount')">
        <div class="card-panel-icon-wrapper icon-money">
          <img :src="purchases">
        </div>
        <div class="card-panel-description">
          <div class="card-panel-text">
            今日对局
          </div>
          <CountTo :start-val="0" :end-val="todayBattleCount" :duration="3200" class="card-panel-num" />
        </div>
      </div>
    </el-col>
    <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col">
      <div class="card-panel" @click="handleSetLineChartData('orderincome')">
        <div class="card-panel-icon-wrapper icon-shopping">
          <img :src="shoppings">
        </div>
        <div class="card-panel-description">
          <div class="card-panel-text">
            今日收入
          </div>
          <CountTo :start-val="0" :end-val="todayOrderIncome / 100" :duration="3600" class="card-panel-num" />
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script lang="ts">
import { DataGame_GMSearchDailyDataStatistic } from '@/apis/data_game'
import messages from '@/assets/images/home/messages.png'
import purchases from '@/assets/images/home/purchases.png'
import shoppings from '@/assets/images/home/shoppings.png'
import visits from '@/assets/images/home/visits.png'
import { EPayType } from '@/constant/network'
import { TodayEnd, TodayStart } from '@/utils/timer'
import { ElMessage } from 'element-plus'
import { defineComponent, onMounted, reactive, toRefs } from 'vue'
import { CountTo } from 'vue3-count-to'
export default defineComponent({
  components: {
    CountTo
  },
  emits: ['handle_set_line_chart_data'],
  setup(_, { emit }) {
    const dataMap = reactive({
      time: Date,
      tableKey: 0,
      listLoading: true,
      todayPlayerNew: 0,
      todayPlayerOnline: 0,
      todayBattleCount: 0,
      todayOrderIncome: 0,
      payTypeCount: {} as { [key: string]: number },
      starttime: TodayStart(),
      endtime: TodayEnd(),
      recordToday: {} as ITServerZoneDailyDataStatisticItem
    })
    const handleSetLineChartData = (type: string) => {
      if (Object.keys(dataMap.payTypeCount).length == 0) {
        dataMap.payTypeCount[EPayType.WeChat] = 0.1;
        dataMap.payTypeCount[EPayType.AliPay] = 0.1;
      }
      const xAxis = [...Array(24)].map((_, i) => `${i}时`);
      let data = {} as any;
      if (type === 'playernew') {
        data["今日新增"] = { data: dataMap.recordToday.HoursPlayerNew };
      } else if (type === 'playeronline') {
        data["今日活跃"] = { data: dataMap.recordToday.HoursPlayerOnline };
      } else if (type === 'battlecount') {
        data["今日对局"] = { data: dataMap.recordToday.HoursBattleCount };
      } else if (type === 'orderincome') {
        xAxis.length = 0;
        data["今日收入"] = { data: [] };
        Object.keys(dataMap.payTypeCount).forEach(v => {
          xAxis.push(EPayType[v as any]);
          data["今日收入"].data.push(dataMap.payTypeCount[v] / 100)
        })
      }
      const paytype = Object.keys(dataMap.payTypeCount).map(V => {
        return { name: EPayType[V as any] || "unknow", value: dataMap.payTypeCount[V] / 100 }
      })
      emit('handle_set_line_chart_data', xAxis, data, paytype);
    }
    const getDataList = async () => {
      dataMap.listLoading = true;
      let msg = {} as IC2G_GMSearchDailyDataStatistic;
      msg.StartTime = Math.ceil(dataMap.starttime.getTime() / 1000);
      msg.EndTime = Math.ceil(dataMap.endtime.getTime() / 1000);
      let cbmsg = await DataGame_GMSearchDailyDataStatistic(msg);
      if (cbmsg?.Error !== 0) {
        ElMessage.error(`GetLogFail: ${cbmsg?.Message}`);
        return;
      }
      if (cbmsg.Message) {
        dataMap.recordToday = JSON.parse(cbmsg.Message) as ITServerZoneDailyDataStatisticItem;
        dataMap.todayPlayerNew = dataMap.recordToday.HoursPlayerNew.reduce((a, b) => a + b, 0) || 0;
        dataMap.todayPlayerOnline = dataMap.recordToday.HoursPlayerOnline.reduce((a, b) => a + b, 0) || 0;
        dataMap.todayBattleCount = dataMap.recordToday.HoursBattleCount.reduce((a, b) => a + b, 0) || 0;
        dataMap.recordToday.OrderIncome.forEach(v => {
          dataMap.todayOrderIncome += v[1];
          dataMap.payTypeCount[v[0] + ""] = dataMap.payTypeCount[v[0] + ""] || 0;
          dataMap.payTypeCount[v[0] + ""] += v[1];
        })

      }
      handleSetLineChartData('playernew');
      dataMap.listLoading = false;
    }
    onMounted(() => {
      getDataList()
    })

    return {
      ...toRefs(dataMap),
      handleSetLineChartData,
      messages,
      purchases,
      visits,
      shoppings
    }
  }
})
</script>

<style lang="scss" scoped>
.panel-group {
  margin-top: 18px;

  .card-panel-col {
    margin-bottom: 32px;
  }

  .card-panel {
    height: 108px;
    cursor: pointer;
    font-size: 12px;
    position: relative;
    overflow: hidden;
    color: #666;
    background: #fff;
    box-shadow: 4px 4px 40px rgba(0, 0, 0, .05);
    border-color: rgba(0, 0, 0, .05);
    height: 124px;
    background: #FFFFFF;
    border-radius: 10px;

    img {
      width: 60px;
      height: 60px;
      display: inline-block;
    }

    .icon-people {
      color: #40c9c6;
    }

    .icon-message {
      color: #36a3f7;
    }

    .icon-money {
      color: #f4516c;
    }

    .icon-shopping {
      color: #34bfa3
    }

    .card-panel-icon-wrapper {
      float: left;
      margin: 14px 0 0 14px;
      padding: 16px;
      transition: all 0.38s ease-out;
      border-radius: 6px;
    }

    .card-panel-icon {
      float: left;
      font-size: 48px;
    }

    .card-panel-description {
      float: right;
      font-weight: bold;
      margin: 26px;
      margin-left: 0px;

      .card-panel-text {
        line-height: 18px;
        color: rgba(0, 0, 0, 0.45);
        font-size: 16px;
        margin-bottom: 12px;
      }

      .card-panel-num {
        font-size: 20px;
      }
    }
  }
}

.mobile {
  .card-panel-icon-wrapper {
    // display: none;
    scale: 50% !important;
    float: none !important;
    width: 20%;
    height: 20%;
    margin: 0 !important;

    svg {
      display: block;
      margin: 5px auto !important;
      float: none !important;
    }
  }
}

@media (max-width:550px) {
  // .card-panel-description {
  // display: none;
  // }

  .card-panel-icon-wrapper {
    // display: none;
    scale: 50% !important;
    float: none !important;
    width: 20%;
    height: 20%;
    margin: 0 !important;

    svg {
      display: block;
      margin: 5px auto !important;
      float: none !important;
    }
  }
}
</style>


import { DataGame_GMSearchDailyDataStatistic } from '@/apis/data_game'
import messages from '@/assets/images/home/messages.png'
import purchases from '@/assets/images/home/purchases.png'
import shoppings from '@/assets/images/home/shoppings.png'
import visits from '@/assets/images/home/visits.png'
import { EPayType } from '@/constant/network'
import { TodayEnd, TodayStart } from '@/utils/timer'
import { ElMessage } from 'element-plus'
import { defineComponent, onMounted, reactive, toRefs } from 'vue'
import { CountTo } from 'vue3-count-to'
export default defineComponent({
  components: {
    CountTo
  },
  emits: ['handle_set_line_chart_data'],
  setup(_, { emit }) {
    const dataMap = reactive({
      time: Date,
      tableKey: 0,
      listLoading: true,
      todayPlayerNew: 0,
      todayPlayerOnline: 0,
      todayBattleCount: 0,
      todayOrderIncome: 0,
      payTypeCount: {} as { [key: string]: number },
      starttime: TodayStart(),
      endtime: TodayEnd(),
      recordToday: {} as ITServerZoneDailyDataStatisticItem
    })
    const handleSetLineChartData = (type: string) => {
      if (Object.keys(dataMap.payTypeCount).length == 0) {
        dataMap.payTypeCount[EPayType.WeChat] = 0.1;
        dataMap.payTypeCount[EPayType.AliPay] = 0.1;
      }
      const xAxis = [...Array(24)].map((_, i) => `${i}时`);
      let data = {} as any;
      if (type === 'playernew') {
        data["今日新增"] = { data: dataMap.recordToday.HoursPlayerNew };
      } else if (type === 'playeronline') {
        data["今日活跃"] = { data: dataMap.recordToday.HoursPlayerOnline };
      } else if (type === 'battlecount') {
        data["今日对局"] = { data: dataMap.recordToday.HoursBattleCount };
      } else if (type === 'orderincome') {
        xAxis.length = 0;
        data["今日收入"] = { data: [] };
        Object.keys(dataMap.payTypeCount).forEach(v => {
          xAxis.push(EPayType[v as any]);
          data["今日收入"].data.push(dataMap.payTypeCount[v] / 100)
        })
      }
      const paytype = Object.keys(dataMap.payTypeCount).map(V => {
        return { name: EPayType[V as any] || "unknow", value: dataMap.payTypeCount[V] / 100 }
      })
      emit('handle_set_line_chart_data', xAxis, data, paytype);
    }
    const getDataList = async () => {
      dataMap.listLoading = true;
      let msg = {} as IC2G_GMSearchDailyDataStatistic;
      msg.StartTime = Math.ceil(dataMap.starttime.getTime() / 1000);
      msg.EndTime = Math.ceil(dataMap.endtime.getTime() / 1000);
      let cbmsg = await DataGame_GMSearchDailyDataStatistic(msg);
      if (cbmsg?.Error !== 0) {
        ElMessage.error(`GetLogFail: ${cbmsg?.Message}`);
        return;
      }
      if (cbmsg.Message) {
        dataMap.recordToday = JSON.parse(cbmsg.Message) as ITServerZoneDailyDataStatisticItem;
        dataMap.todayPlayerNew = dataMap.recordToday.HoursPlayerNew.reduce((a, b) => a + b, 0) || 0;
        dataMap.todayPlayerOnline = dataMap.recordToday.HoursPlayerOnline.reduce((a, b) => a + b, 0) || 0;
        dataMap.todayBattleCount = dataMap.recordToday.HoursBattleCount.reduce((a, b) => a + b, 0) || 0;
        dataMap.recordToday.OrderIncome.forEach(v => {
          dataMap.todayOrderIncome += v[1];
          dataMap.payTypeCount[v[0] + ""] = dataMap.payTypeCount[v[0] + ""] || 0;
          dataMap.payTypeCount[v[0] + ""] += v[1];
        })

      }
      handleSetLineChartData('playernew');
      dataMap.listLoading = false;
    }
    onMounted(() => {
      getDataList()
    })

    return {
      ...toRefs(dataMap),
      handleSetLineChartData,
      messages,
      purchases,
      visits,
      shoppings
    }
  }
})
